import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzCountryAutocompleteComponent } from './kurz-country-autocomplete.component';
import { AutocompleteModule } from '@util/components/autocomplete/autocomplete.module';



@NgModule({
  declarations: [
    KurzCountryAutocompleteComponent
  ],
  imports: [
    CommonModule,
    AutocompleteModule
  ],
  exports: [
    KurzCountryAutocompleteComponent
  ]
})
export class KurzCountryAutocompleteModule { }
